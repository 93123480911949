import { Box, Text } from "grommet";
import React from "react";

export default function TransInfo({ trans, merchant }) {
  return (
    <>
      <Box
        pad={{ vertical: "small" }}
        // overflow={{ horizontal: "hidden", vertical: "scroll" }}
      >
        <Text color="light-6">{"Welcome"}</Text>
        <Text weight="bold">
          {trans.first_name} {trans.last_name}
        </Text>
      </Box>
      <Box pad={{ vertical: "small" }}>
        <Box
          margin={{ vertical: "medium" }}
          pad="small"
          border={{
            color: "brand",
            side: "left",
            size: "5px",
          }}
        >
          <Text size="small">
            {"Please pay to "}
            <Text size="small" color="brand">
              {merchant.name}
            </Text>
          </Text>
          <Text
            level="1"
            eight="bold"
            margin="none"
          >{`EGP ${trans.amount}`}</Text>
        </Box>
      </Box>
    </>
  );
}

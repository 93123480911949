import { Box, Heading, Image, Text } from "grommet";
import * as moment from "moment";
import React from "react";

export default function PaidTrans({ trans, merchant }) {
  return (
    <>
      <Box pad={{ vertical: "medium" }}>
        <Text color="light-6">{"Welcome"}</Text>
        <Text weight="bold">
          {trans.first_name} {trans.last_name}
        </Text>
      </Box>
      <Box pad={{ vertical: "medium" }} direction="row">
        <Box
          width="48px"
          height="48px"
          background={{
            color: "#00BE00",
            opacity: 0.2,
          }}
          round="50%"
          align="center"
          direction="row"
          justify="center"
          pad={{ top: "25px", left: "10px", right: "10px" }}
        >
          <Image
            src={require("../images/done.png")}
            width="48px"
            height="48px"
          />
        </Box>
        <Box pad={{ horizontal: "small" }}>
          <Text weight="bold" color="neutral-1">
            {"Payment Successful"}
          </Text>
          <Text>{`your payment paid to your merchant successfully.`}</Text>
        </Box>
      </Box>
      <Box pad={{ vertical: "medium" }}>
        <Box
          margin={{ vertical: "medium" }}
          pad="medium"
          border={{
            color: "brand",
            side: "left",
            size: "5px",
          }}
        >
          <Text size="small">
            {"Paid to "}
            <Text size="small" color="brand">
              {merchant.name}
            </Text>
          </Text>
          <Heading level="1" margin="none">{`EGP ${trans.amount}`}</Heading>
          <Text size="small">
            {"Paid at  "}
            <Text size="small" color="brand">
              {moment(trans.paid_at).format("lll")}
            </Text>
          </Text>
        </Box>
      </Box>
    </>
  );
}
